<template>
  <v-menu transition="slide-y-transition" nudge-left="40" offset-y v-model="showMenu">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card class="mx-auto" max-width="300">
      <v-list dense>
        <v-list-item v-for="(action, i) in actions" :key="i" :disabled="action.disabled"
          @click.stop="actionClicked(action)">
          <v-list-item-action :disabled="action.disabled">
            <v-icon :color="action.color || undefined">{{ action.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="mr-3">
            <v-list-item-title :class="action.color ? `${action.color}--text` : undefined">
              {{ action.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <ContractUploadModal
      v-if="uploadModal"
      v-model="uploadModal"
      :reupload="reupload"
      :readOnly="readOnly"
      :selectedContract="selectedContract"
      :uploadId="selectedContract.upload_id"
      @closeUpload="closeUploadModal">
    </ContractUploadModal>
    <CancelDialog
      v-if="cancelDialog"
      v-model="cancelDialog"
      :selectedContract="selectedContract"
      @closeCancel="closeCancelDialog">
    </CancelDialog>
    <FinalizeDialog
      v-if="finalizeDialog"
      v-model="finalizeDialog"
      :selectedContract="selectedContract"
      @closeFinalize="closeFinalizeDialog">
    </FinalizeDialog>
  </v-menu>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
const ContractUploadModal = () => import('@/components/contracts/ContractUploadModal.vue')
const CancelDialog = () => import('@/components/contracts/CancelDialog.vue')
const FinalizeDialog = () => import('@/components/contracts/FinalizeDialog.vue')
export default {
  name: 'ContractActions',
  mixins: [userAccess, displayAlert],
  components: { ContractUploadModal, CancelDialog, FinalizeDialog },
  props: [
    'selectedContract'
  ],
  data() {
    return {
      reupload: false,
      readOnly: false,
      showMenu: false,
      uploadModal: false,
      cancelDialog: false,
      finalizeDialog: false
    }
  },
  computed: {
    actions() {
      return [
        { label: 'Finalize', value: 'finalize', icon: 'mdi-alert-octagon-outline', disabled: false, show: this.showFinalize, color: 'error' },
        { label: 'Rerun', value: 'rerun', icon: 'mdi-refresh', disabled: false, show: this.showRerun },
        { label: 'Cancel', value: 'cancel', icon: 'mdi-cancel', disabled: false, show: this.showCancel },
        { label: 'Download Warning Log', value: 'download_warnings', icon: 'mdi-file-outline', disabled: false, show: this.showDownloadLog },
        { label: 'Download Contract File', value: 'download', icon: 'mdi-download', disabled: false, show: true },
        { label: 'View Upload Details', value: 'payload_values', icon: 'mdi-text-box-search-outline', disabled: false, show: this.showViewPayload }
      ].filter(action => action.show)
    },
    status() {
      return this.selectedContract.upload_status.toLowerCase()
    },
    showFinalize() {
      return ['pending_with_warnings'].includes(this.status)
    },
    showRerun() {
      return ['exception', 'failed', 'pending_with_warnings'].includes(this.status)
    },
    showCancel() {
      return ['exception', 'failed', 'pending_with_warnings'].includes(this.status)
    },
    showDownloadLog() {
      return (this.hasWarningLog || this.hasErrorLog)
    },
    hasWarningLog () {
      return (this.$auth.tenant === 'alliance-retail-group')
        ? this.selectedContract.warning_reason?.toLowerCase()?.includes('see log for more details')
        : Boolean(this.selectedContract.warning_reason)
    },
    hasErrorLog () {
      return this.selectedContract.failure_reason?.toLowerCase()?.includes('see log for more details')
    },
    showViewPayload() {
      return this.selectedContract.payload && (Object.entries(this.selectedContract.payload).length > 0)
    }
  },
  methods: {
    actionClicked(action) {
      switch (action.value) {
        case 'download':
          this.download()
          break
        case 'rerun':
          this.reupload = true
          this.uploadModal = true
          break
        case 'cancel':
          this.cancelDialog = true
          break
        case 'payload_values':
          this.readOnly = true
          this.uploadModal = true
          break
        case 'download_warnings':
          this.downloadWarningLog()
          break
        case 'finalize':
          this.finalizeDialog = true
          break
      }
    },
    async download () {
      try {
        const { data } =  await ContractUpload.downloadContract({ upload_id: this.selectedContract.upload_id })
        if (data) {
          location.href = data.url
        }
        this.showMenu = false
      } catch (err) {
        this.handleError(err)
      }
    },
    async downloadWarningLog () {
      try {
        const { data } =  await ContractUpload.downloadWarningLog(this.selectedContract.upload_id)
        if (data) {
          location.href = data.url
        }
        this.showMenu = false
      } catch (err) {
        this.handleError(err)
      }
    },
    closeUploadModal (uploaded = false) {
      this.uploadModal = false
      if (uploaded) {
        this.$emit('refresh')
      }
      this.reupload = false
      this.readOnly = false
      this.showMenu = false
    },
    closeCancelDialog (cancelled = false) {
      this.cancelDialog = false
      if (cancelled) {
        this.$emit('refresh')
      }
      this.showMenu = false
    },
    closeFinalizeDialog (finalized = false) {
      this.finalizeDialog = false
      if (finalized) {
        this.$emit('refresh')
      }
      this.showMenu = false
    }
  }
}
</script>